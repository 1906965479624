export const navLinks = [
  {
    label: 'About',
    pathname: '/',
    hash: '#about',
  },
  {
    label: 'Bond',
    pathname: '/',
    hash: '#bond',
  },
  {
    label: 'Stake',
    pathname: '/',
    hash: '#stake',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
  {
    label: 'Community',
    pathname: '/community',
  }
];

export const socialLinks = [
  {
    label: 'Twitter',
    url: 'https://twitter.com/nftrobin',
    icon: 'twitter',
  },
  {
    label: 'Instagram',
    url: 'https://instagram.com/nftrobin?igshid=uiydmf9in6ld',
    icon: 'instagram',
  },
];
