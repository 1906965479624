import './index.css';
import { ReactComponent as PalmTreeLogo } from 'assets/palm-tree-logo.svg';

function Monogram({ highlight, className, ...props }) {
  return (
    <PalmTreeLogo></PalmTreeLogo>
  );
}

export default Monogram;
